import Helmet from 'preact-helmet';
import RightPanel from '../../components/right';

const TechnologieVyroba = () => (
	<div>
		<Helmet title="Technologie a výroba balení do sáčků" />
		<div className="first_container">
			<div className="story">
				<div className="breadcrumb">
					<h1>Technologie a výroba balení do sáčků</h1>
					<ul id="nav">
						<li><a href="/" >Home</a>&nbsp;&nbsp;&raquo;&nbsp;</li>
						<li>Technologie a výroba</li>
					</ul>
				</div>
				<h3 id="investice">Investujeme do technologického vybavení!</h3>
				<p>Protože stále držíme krok s konkurencí, neustále investujeme do
				vybavení potřebného k dosažení co nejlepších možných výsledků pro
			zvýšení efektivity a produktivity práce .</p>
				<h3>Balící stroje a výrobní linky</h3>
				<ul>
					<li>horizontální balící linka <a href="/technologie-vyroba/sl140/">SL140</a></li>
					<li>vertikální balící linka <a href="/technologie-vyroba/v250/">V250</a></li>
				</ul>
				<h3>Kkategorie zboží</h3>
				<ul>
					<li>sypké (granulované, práškové, krystalické, těžkosypné)</li>
					<li>tekuté (na bázi vody, oleje a lepidla)</li>
					<li>kusové (potravinářské výrobky, drobné pečivo nebo sušenky)</li>
				</ul>
				<h3>Plnění – dávkovací zařízení</h3>
				<ul>
					<li>Sypkých hmot</li>
					<li>tekutých hmot</li>
					<li>kusového zboží</li>
				</ul>
				<h3>Sáčky – Velikosti balení</h3>
				<ul>
					<li>ploché – horizontální 50×50mm –160×200mm</li>
					<li>hadicové – vertikální 30×30mm –100×280mm</li>
				</ul>
				<h3>Potisk folií</h3>
				<p>Jednobarevný potisk v barvě černé, modré, červené a zelené. Velikost
				plochy potisku 50×70mm je možný přímo na balící lince, ostatní
				plnobarevné potisky zajišťují dodavatelské tiskárny, které používají
			technologii digitálního tisku, hlubotisku nebo flexotisku.</p>
			</div>
			<RightPanel />
			<div class="clear" />
		</div>
	</div>
);
export default TechnologieVyroba;